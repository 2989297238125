import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import InfoCard from '../info-card/InfoCard';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './InfoPanel.module.css';

class InfoPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isMobile: window.innerWidth < 768, // Track mobile state based on window size
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize); // Add resize listener
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize); // Clean up listener
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth < 768 }); // Update state on resize
    };

    toggleDrawer = (open) => {
        this.setState({ isOpen: open });
    };

    render() {
        const { isMobile, isOpen } = this.state;

        return (
            <div>
                {isMobile && (
                    <IconButton
                        edge="start"
                        className={styles.menuButton}
                        color="inherit"
                        onClick={() => this.toggleDrawer(true)}
                        aria-label="menu"
                    >
                        <MenuIcon />
                    </IconButton>
                )}

                {isMobile && (
                    <Drawer
                        className={styles.drawer}
                        variant="temporary"
                        anchor="left"
                        open={isOpen}
                        onClose={() => this.toggleDrawer(false)}
                        classes={{ paper: styles.drawerPaper }}
                    >
                        <InfoCard />
                    </Drawer>
                )}

                {!isMobile && (
                    <div className={styles.infoCardDesktop}>
                        <InfoCard />
                    </div>
                )}
            </div>
        );
    }
}

export default InfoPanel;
