import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import StarIcon from '@material-ui/icons/Star';
import profile from '../../data/profile.json';
import styles from './InfoCard.module.css';

class InfoCard extends Component {
    render() {
        return (
            <div>
                <Grid container className={styles.infoGrid} justify="center" alignItems="center">
                    <Grid item>
                        <Avatar alt={profile.Name} className={styles.bigAvatar} src={require("../../img/self1.jpg")} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" className={styles.name}>
                            {profile.Name}
                        </Typography>
                        <Typography variant="h5" className={styles.position}>
                            {profile.Position}
                        </Typography>
                        <Typography variant="h6" className={styles.introduction}>
                            {profile.Description}
                        </Typography>
                    </Grid>
                    <Divider className={styles.divider} variant="middle" />
                    <Grid item xs={12}>
                        <List className={styles.infoList}>
                            <ListItem>
                                <Avatar className={styles.infoIcon}>
                                    <LocationCityIcon />
                                </Avatar>
                                <ListItemText primary={profile.Address} />
                            </ListItem>
                            <ListItem>
                                <Avatar className={styles.infoIcon}>
                                    <WorkIcon />
                                </Avatar>
                                <ListItemText primary={profile.Company} />
                            </ListItem>
                            <ListItem>
                                <Avatar className={styles.infoIcon}>
                                    <SchoolIcon />
                                </Avatar>
                                <ListItemText primary={profile.School} />
                            </ListItem>
                            <ListItem>
                                <Avatar className={styles.infoIcon}>
                                    <PhoneIcon />
                                </Avatar>
                                <ListItemText primary={<a href={`tel:${profile.Phone}`}>{profile.Phone}</a>} />
                            </ListItem>
                            <ListItem>
                                <Avatar className={styles.infoIcon}>
                                    <EmailIcon />
                                </Avatar>
                                <ListItemText primary={<a href={`mailto:${profile.Email}`}>{profile.Email}</a>} />
                            </ListItem>
                            <ListItem>
                                <Avatar className={styles.infoIcon}>
                                    <LinkedInIcon />
                                </Avatar>
                                <ListItemText 
                                    primary={<a href="https://www.linkedin.com/in/yanluzhong" target="_blank" rel="noopener noreferrer">Yanlu Zhong</a>} 
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Divider className={styles.divider} variant="middle" />

                    {/* Core Skills Section */}
                    <Grid item xs={12}>
                        <Typography variant="h6" className={`${styles.coreSkillsHeading} ${styles.leftAlign}`}>
                            <Avatar className={styles.infoIcon}>
                                <StarIcon />
                            </Avatar>
                            Core Skills
                        </Typography>
                        <List className={styles.coreSkillsList}>
                            <ListItem>
                                <ListItemText primary="1. Market Insight" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="2. Video Marketing and Brand Strategy" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="3. Cross-Cultural Communication" />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default InfoCard;
