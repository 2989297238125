import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import ExperiencePanel from '../experience-panel/ExperiencePanel';
import SkillPanel from '../skill-panel/SkillPanel';
import EducationPanel from '../education-panel/EducationPanel';
import ProjectTabPanel from '../project-tabpanel/ProjectTabPanel';
import SwipeableViews from 'react-swipeable-views';
import styles from './DetailTabPanel.module.css';

class DetailTabPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            isMobile: window.innerWidth < 768, // Track mobile state
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth < 768 }); // Update mobile state
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    render() {
        const { value, isMobile } = this.state;
        const { theme } = this.props;

        return (
            <div className={`${styles.tabPanelRoot} ${isMobile ? styles.mobile : ''}`}>
                <AppBar position="static">
                    <Tabs value={value} variant="fullWidth" onChange={this.handleChange}>
                        <Tab className={styles.tabPanelText} label="My Experience" />
                        <Tab className={styles.tabPanelText} label="My Skills" />
                        <Tab className={styles.tabPanelText} label="My Personal Projects" />
                        <Tab className={styles.tabPanelText} label="My Education" />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    animateHeight={true}
                    className={styles.tabContainer}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={this.state.value}
                    onChangeIndex={this.handleChangeIndex}
                >
                    <ExperiencePanel dir={theme.direction} />
                    <SkillPanel dir={theme.direction} />
                    <ProjectTabPanel dir={theme.direction} />
                    <EducationPanel dir={theme.direction} />
                </SwipeableViews>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(DetailTabPanel);
